<template>
  <section>
    <b-card>
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche principe :
          </span>
          <span style="font-size: 25px" class="font-weight-bold">{{
            PRINCIPE.name
          }}</span>
        </p>
        <div class="row mb-4">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p
            class="h4 text-secondary my-auto mr-auto"
            style="margin: 6.656px 0 !important"
          >
            Informations générales
          </p>
          <a
            v-if="(isAdmin || isSuper || isArchitect) && !editMode"
            class="text-secondary"
            style="font-size: 30px; color: #007bff !important; cursor: pointer"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div
            v-else-if="isAdmin || isSuper || isArchitect"
            class="m-0 d-flex p-0"
          >
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="bg-secondary mb-3 mt-1" />
        </div>
        <div class="row" v-if="!editMode">
          <div class="col-md-6 col-12">
            <div class="my-4">
              <span>Libellé:</span>
              <span class="font-weight-bold"> {{ PRINCIPE.name }} </span>
            </div>
            <div class="my-4">
              <span>Code:</span>
              <span class="font-weight-bold"> {{ PRINCIPE.code }} </span>
            </div>
            <div class="my-4">
              <span>Date de création:</span>
              <span class="font-weight-bold">
                {{
                  moment(PRINCIPE.createdAt, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  ) || "-"
                }}
              </span>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="my-4">
              <span>Description:</span>
              <span class="font-weight-bold"> {{ PRINCIPE.description }} </span>
            </div>
          </div>
        </div>

        <!-- inputs -->
        <div class="row" v-else>
          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Libellé:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  type="text"
                  placeholder="Libellé"
                  :class="{
                    'is-invalid':
                      $v.newPrincipe.name.$error && $v.newPrincipe.name.$dirty,
                  }"
                  v-model="newPrincipe.name"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newPrincipe.name.$dirty"
                >
                  {{
                    !$v.newPrincipe.name.required
                      ? "Champ obligatoire"
                      : !$v.newPrincipe.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newPrincipe.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Code:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  type="text"
                  placeholder="Code"
                  :class="{
                    'is-invalid':
                      $v.newPrincipe.code.$error && $v.newPrincipe.code.$dirty,
                  }"
                  v-model="newPrincipe.code"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newPrincipe.code.$dirty"
                >
                  {{ !$v.newPrincipe.code.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Date de création:</label>
              </div>
              <div class="col-md-8">
                <date-picker
                  type="text"
                  placeholder="Date de création"
                  class="w-100"
                  :value="new Date()"
                  format="DD/MM/YYYY"
                  disabled
                ></date-picker>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Description:</label>
              </div>
              <div class="col-md-8">
                <b-form-textarea
                  type="text"
                  placeholder="Description"
                  v-model="newPrincipe.description"
                ></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- end inputs -->
      </div>

      <hr style="margin-top: 30px" />
      <!-- Tabs -->
      <div style="margin-top: -20px">
        <b-tabs class="tab-solid tab-solid-primary mt-5">
          <b-tab title="Cadres rattachés">
            <div class="d-flex">
              <span class="h4 text-secondary mr-auto my-3"
                >Liste des Cadres
              </span>
            </div>
            <div class="col-12 p-0">
              <hr class="mt-1 mb-3 bg-secondary" />
              <Cadres
                :disabled="!(isAdmin || isSuper || isArchitect)"
                :fields="cadre.fields"
                :libelle="PRINCIPE.name"
              />
            </div>
          </b-tab>
          <b-tab v-if="false" title="Implémentation">
            <Implementation
              :disabled="!(isAdmin || isSuper || isArchitect)"
              :fields="impl.fields"
              :items="[]"
            />
          </b-tab>
          <b-tab v-if="false" title="Couverture project">
            <Implementation
              :disabled="!(isAdmin || isSuper || isArchitect)"
              :fields="impl.fields"
              :items="[]"
            />
          </b-tab>
        </b-tabs>
      </div>

      <!-- End Tabs -->
    </b-card>
    <!-- Add Cadre Modal -->
    <div to="modals-xyz-548" v-if="showAddCadre">
      <modal @close="showAddCadre = !showAddCadre">
        <add-cadre />
      </modal>
    </div>

    <!-- Add Object Modal -->
    <div to="modals-xyz-548" v-if="showAddObject">
      <modal @close="showAddObject = !showAddObject">
        <add-object />
      </modal>
    </div>
  </section>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";

import Cadres from "./components/Cadres.vue";
import Implementation from "./components/Implementations.vue";
import Modal from "@/components/custom/Modal.vue";
import AddCadre from "./components/AddCadre.vue";
import AddObject from "./components/AddObject.vue";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";

export default {
  components: {
    ModalActions,
    Cadres,
    Implementation,
    Modal,
    AddCadre,
    AddObject,
  },
  data: () => ({
    showAddCadre: false,
    showAddObject: false,
    editMode: false,
    cadre: {
      items: [
        {
          type: "Regles",
          code: 56,
          libelle: "Api station",
          couverture: 30,
          status: "Production",
          capacite: "Services d'echanges",
          patrimoine: 4,
          livrable: 2,
          // actions: ["edit", "delete", "show"],
          actions: ["show"],
        },
      ],
      fields: [
        { key: "type", label: "Type cadre", sortable: true },
        // { key: "code", label: "Code", sortable: true },
        { key: "name", label: "Cadre", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "couverture", label: "Couverture projet", sortable: true },
        { key: "capacites", label: "Capacite technique", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "fileCadres", label: "Livrables", sortable: true },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   thStyle: {width: "150px !important", minWidth: "150px !important"},
        //
        //   sortable: false,
        // },
      ],
    },
    impl: {
      items: [
        {
          type: "Composant Technique",
          libelle: "OVH",
          po: "O. GUISSE",
          capacite: "Cloud",
          patrimoine: 4,
          // actions: ["edit", "delete", "show"],
          actions: ["delete"],
        },
      ],
      fields: [
        { key: "type", label: "Type object", sortable: true },
        { key: "libelle", label: "Libellé object", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
        { key: "patrimoine", label: "patrimoine", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
    newPrincipe: {
      name: "",
      description: "",
      code: "",
    },
  }),
  validations: {
    newPrincipe: {
      name: {
        required,
        minLength: minLength(5),
      },
      code: {
        required,
      },
    },
  },
  created() {
    this.$store
      .dispatch("principe/fetchPrincipe", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
  },
  computed: {
    ...mapGetters("principe", ["PRINCIPE"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
  },
  methods: {
    updateItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store
          .dispatch("principe/updatePrincipe", {
            id: this.$route.params.id,
            ...this.newPrincipe,
          })
          .then(() => {
            this.$store
              .dispatch("principe/fetchPrincipe", this.$route.params.id)
              .then(() => {
                Swal.fire("Le principe est mis à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },
  },
  watch: {
    PRINCIPE: function () {
      this.newPrincipe = {
        ...this.newPrincipe,
        name: this.PRINCIPE.name,
        description: this.PRINCIPE.description,
        code: this.PRINCIPE.code,
      };
    },
  },
};
</script>
